/**
 * @description This file is the base file for loading als js and css assets into /theme/assets
 * 
 * @see https://plate.atlassian.net/wiki/spaces/PFD/pages/1356922881/Conditioneel+javascript+inladen+in+index.js
*/

import { inEditor } from './scripts/functions/inEditor.js'
import {
  intersectionObserver
} from './scripts/functions/observers/intersectionObserver';

/** 
 * @description  Conditional assets
 * @param {Array} assets - Array with parameters for conditional assets
*/

const assets = [
  {
    query: '.quotation_form',
    loader: async (scope) => {
      let imp = await import('./scripts/modules/form/quotation_form.js');
      imp.form(scope);
    }
  },
  {
    query: '.plate--element__form form, .plate--element__step_form form',
    loader: async (scope) => {
      let imp = await import('./scripts/modules/form/form.js');
      imp.form(scope);
    }
  },
  // {
  //   query: '.selector',
  //   loader: async (scope) => {
  //     let imp = await import('./scripts/modules/selector');
  //     imp.selectorInit(scope);
  //   }
  // },

]


/** 
 * @description  Loading each asset asynchronous and conditional
*/

let assetPromises = assets.map(a => {
  return new Promise((resolve, reject) => {
    function loadAsset(scope) {
      if (document.querySelector(a.query)) {
        a.loader(scope).then(() => {
          resolve(true)
        }).catch((e) => {
          console.log(e);
          reject()
        })
      } else {
        resolve(false)
      }
    }
    loadAsset(document)

    document.addEventListener('plateContentAdded', e => {
      loadAsset(e.detail.element)
    })
  })
})

/** 
 * @description  Loading each asset asynchronous and conditional
*/

Promise.all(assetPromises).then((values) => {
  intersectionObserver(document);
  document.addEventListener('plateContentAdded', e => {
    intersectionObserver(e.detail.element);
  })
});


/** 
 * @description Assets which only need to be loaded when there is an iframe 
 */
async function loadAssetsIframe() {
  if (inEditor()) {
    let loadMutationObserver = await import('./scripts/functions/mutationObserver');
    loadMutationObserver.mutationObserver();
  }
}
loadAssetsIframe()

/** 
 * @description Assets which are always needed
 */

import { isTouchDevice } from './scripts/functions/isTouchDevice'
if (isTouchDevice()) {
  document.body.classList.add('is_touch_device')
}


// import {
// 	animationObserver
// } from './scripts/functions/observers/animationObserver';
// animationObserver();

/** 
 * @description Loading styles
 */
import './styles/style.scss';
