//Object used for intersectionObserver
export const observerAssets = {
   observerElements: [
      {
         name: "img.lazy",
         rootMargin: "0px 0px 400px 0px",
         outViewportClass: ["bg-lazy", "lazy"],
         outViewportAttr: "src",
         inViewportAttr: "src"
      }, {
         name: "source.lazy",
         outViewportClass: ["lazy"],
         outViewportAttr: "srcset",
         inViewportAttr: "srcset"
      }, {
         name: ".image--lazy",
         outViewportClass: ["image--lazy"],
      }, {
         name: ".section .plate--row",
         animationItems: ".animate_el",
         animationDelay: 500
      },
   ]
};

//Object used for animationObserver
export const observerAnimations = {
   observerAnimations: [

   ]
};