import { observerAssets } from '../../variables/observerVars/observerVars';

export function intersectionObserver(scope) {

   observerAssets.observerElements.forEach(observeEl => {

      //Set root and rootMargin
      const elRoot = observeEl.root ? observeEl.root : null;
      const elRootMargin = observeEl.rootMargin ? observeEl.rootMargin : '0px';

      const observerOptions = {
         root: elRoot,
         rootMargin: elRootMargin
      }

      var entryObserver = new IntersectionObserver(function (entries) {
         //Check values of the observed elements
         entries.forEach(entry => {
            const targetEl = entry.target;
            //Check if observed element is intersecting with the viewport
            if (entry.isIntersecting) {
               if (targetEl.classList.contains('plate--row')) {
                  //console.log(targetEl);
               }
               //Dispatch event
               if (observeEl.inViewportEvent) {
                  targetEl.dispatchEvent(new Event('inViewport'));
               }
               //Check for animation Items
               if (observeEl.animationItems) {
                  targetEl.querySelectorAll(observeEl.animationItems).forEach((animationItem, index) => {
                     let animationClass = animationItem.dataset.animation_class ? animationItem.dataset.animation_class : animationItem.parentElement.dataset.animation_class;
                     let delay = observeEl.animationDelay ? observeEl.animationDelay : 1000;
                     setTimeout(function () {
                        if (observeEl.animationClass) {
                           animationItem.classList.add(observeEl.animationClass);
                        } else {
                           animationItem.classList.add(animationClass);
                        }
                     }, index * delay);
                  })
               }
               //Add classes to observed element
               if (observeEl.inViewportClass) {
                  targetEl.classList.add(...observeEl.inViewportClass);
               }
               //Remove classes from observed element
               if (observeEl.outViewportClass) {
                  targetEl.classList.remove(...observeEl.outViewportClass);
               }
               //Remove the out of viewport attribute and add the value to the inViewport attribute
               if (observeEl.outViewportAttr && targetEl.dataset[observeEl.outViewportAttr]) {
                  targetEl[observeEl.inViewportAttr] = targetEl.dataset[observeEl.outViewportAttr];
                  targetEl.removeAttribute('data-' + observeEl.outViewportAttr);
               }
               //Unobserve element
               if (!observeEl.repeat) {
                  entryObserver.unobserve(targetEl);
               }
            } else {
               //Dispatch event
               if (observeEl.outViewportEvent) {
                  targetEl.dispatchEvent(new Event('outViewport'));
               }
               //Add classes to observed element
               if (observeEl.outViewportClass) {
                  targetEl.classList.add(...observeEl.outViewportClass);
               }
               //Remove classes from observed element
               if (observeEl.inViewportClass) {
                  targetEl.classList.remove(...observeEl.inViewportClass);
               }
            }

         })
      }, observerOptions);

      //Observe all elements
      scope.querySelectorAll(observeEl.name).forEach(el => {
         entryObserver.observe(el);
      })
   })

}